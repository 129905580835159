// Imports libraries
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// TRIAL BUTTON <a href="/trial"><button class="primary space" id="join">{text_home["page"]["options"]["trial"]}</button></a>

// Imports loacles
import locale_home from "../locale/home.json";
import locale_characteristics from "../locale/characteristics.json";

// Imports components
import Row from "../components/atoms/row";
import Card from "../components/atoms/card";
import Column from "../components/atoms/column";
import Spacer from "../components/atoms/spacer";
import Banner from "../components/molecules/banner";
import Top from "../components/organisms/top";
import Aspect from "../components/organisms/aspect";
import Bottom from "../components/organisms/bottom";

// Accesses home page
export default
function Thanks
(props)
{
    return (
        <React.Fragment>
            
            <Top title="Thank you" hero="You successfully completed your Team 360 Questionnaire">
                <Row>
                    <a href="/"><button class="primary space" id="home">Go Home</button></a>
                </Row>
            </Top>

            <Bottom />

        </React.Fragment>
    );

}