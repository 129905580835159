import jsPDF from 'jspdf';
import { database as db } from "../../index";
import { collection, doc, getDoc, getDocs, deleteDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";

import Cover from "./team360/cover.jsx";
import Contents from "./team360/contents.jsx";
import Introduction from "./team360/introduction.jsx";
import Overview from "./team360/overview.jsx";
import Breakdown from "./team360/breakdown.jsx";
import Summary from './team360/summary.jsx';
import Comments from './team360/comments.jsx';

import "../styles/fonts/futura-pt-book-normal.js";
import "../styles/fonts/futura-pt-bold-normal.js";

export const sizes = {
    marginX: 13.5,
    marginY: 13.5,
    h1: 60,
    h2: 32,
    h3: 24,
    p: 18,
    s: 13,
    t: 12,
    k: 12,
    c: 13.5,
    pageWidth: 297-2*13.5,
    fullPageWidth: 297
}

export const colours = {
    primary: "#FFFFFF",
    accent: "#C2091F",
    secondary: "#757575",
    auxiliary: "#3B5F6F",
    background: "#343A40",
    favourable: "#358BB7",
    neutral: "#ABABAB",
    unfavourable: "#E3072A",
    stronglyagree: "#358BB7",
    agree: "#B6D3EE",
    undecided: "#ABABAB",
    disagree: "#FC92A4",
    stronglydisagree: "#E3072A",
}

const text = (doc, txt, offsetX, offsetY, sizeX, align, colour, size) => {
    if      (sizeX < 0)                             sizeX = sizes.pageWidth;
    if      (align === "center"  && offsetX < 0)    offsetX = sizes.pageWidth / 2;
    else if (align === "left"    && offsetX < 0)    offsetX = 0;
    doc.setFontSize(size);
    doc.setTextColor(colour);
    doc.text(doc.splitTextToSize(txt, sizeX, {fontName: "FuturaPT-Book"}), sizes.marginX+offsetX, sizes.marginY+offsetY, {align: align});
}

export const h1 = (doc, txt, {offsetX=-1, offsetY=0, sizeX=-1, align="left"}) => {
    doc.setFont("futura-pt-bold", "normal");
    text(doc, txt, offsetX, offsetY, sizeX, align, colours.auxiliary, sizes.h1);
}

export const h2 = (doc, txt, {offsetX=-1, offsetY=0, sizeX=-1, align="left"}) => {
    doc.setFont("futura-pt-bold", "normal");
    text(doc, txt, offsetX, offsetY, sizeX, align, colours.auxiliary, sizes.h2);
}

export const h3 = (doc, txt, {offsetX=-1, offsetY=0, sizeX=-1, align="left"}) => {
    doc.setFont("futura-pt-bold", "normal");
    text(doc, txt, offsetX, offsetY, sizeX, align, colours.secondary, sizes.h3);
}

export const p = (doc, txt, {offsetX=-1, offsetY=0, sizeX=-1, align="left"}) => {
    doc.setFont("futura-pt-book", "normal");
    text(doc, txt, offsetX, offsetY, sizeX, align, colours.secondary, sizes.p);
}

export const w = (doc, txt, {offsetX=-1, offsetY=0, sizeX=-1, align="left"}) => {
    doc.setFont("futura-pt-book", "normal");
    text(doc, txt, offsetX, offsetY, sizeX, align, colours.primary, sizes.p);
}

export const c = (doc, txt, {offsetX=-1, offsetY=0, sizeX=-1, align="left"}) => {
    doc.setFont("futura-pt-bold", "normal");
    text(doc, txt, offsetX, offsetY, sizeX, align, colours.primary, sizes.c);
}

export const s = (doc, txt, {offsetX=-1, offsetY=0, sizeX=-1, align="left"}) => {
    doc.setFont("futura-pt-book", "normal");
    text(doc, txt, offsetX, offsetY, sizeX, align, colours.secondary, sizes.s);
}

export const k = (doc, txt, {offsetX=-1, offsetY=0, sizeX=-1, align="left"}) => {
    doc.setFont("futura-pt-book", "normal");
    text(doc, txt, offsetX, offsetY, sizeX, align, colours.primary, sizes.k);
}

export const t = (doc, txt, {offsetX=-1, offsetY=0, sizeX=-1, align="left"}) => {
    doc.setFont("futura-pt-book", "normal");
    text(doc, txt, offsetX, offsetY, sizeX, align, colours.secondary, sizes.t);
}

export const img = (doc, link, xPos, yPos, imgWidth, imgHeight, type="png") => 
    doc.addImage(link, type, xPos, yPos, imgWidth, imgHeight);

export const page = (doc, pageSize="a4", orientation="l") =>
    doc.addPage(pageSize, orientation);

export const logo = (doc, xPos, yPos) => {
    const link = "/assets/images/icons/brands/performance-edge-logo.png"
    const [width, height] = [80, 30];
    xPos = xPos - (width / 2);
    img(doc, link, xPos, yPos, width, height);
}

async function getReportContent (doc, participants, questionnaire) {

    await Cover         (doc, questionnaire.team_name, questionnaire.deadline);
    await Contents      (doc, participants);
    await Introduction  (doc);
    await Overview      (doc, participants);

    await Breakdown     (
                            doc,
                            "Purpose",
                            participants,
                            1,
                            6,
                            "A statement of why the team exists. The purpose statement captures the spirit of how the team works together to uniquely contribute towards the goals of its own organisation, its stakeholders, and the wider system. The statement only has weight when accompanied by collective performance goals.",
                            "This team has a clear and compelling reason for existence - its work is meaningful, worthwhile, and challenging.",
                            "Each team member understands how the work of the team contributes to the vision, strategy, and goals of both the organisation and its external stakeholders.",
                            "The team has collective goals that they can only achieve by working together.",
                            "Team members understand how they personally contribute towards the team's success.",
                            "When the team fails to achieve collective goals, each team member personally takes responsibility to improve the team's performance.",
                            "Performance is regularly reviewed by this team using clear and relevant metrics.",
                        );
    
    await Breakdown     (
                            doc,
                            "Identity",
                            participants,
                            7,
                            12,
                            "The team works on developing their unique identity collectively. Their identity is the glue that binds them together and constantly reinforces their positive mindset, energy, and motivation. Their identity will be recognised and admired by both those inside and outside the team.",
                            "This team has a unique character and personality that any team member would be proud to identify with.",
                            "People from outside this team say they can count on the team to do what they say they will do.",
                            "This team has a reputation for delivering excellent work among its stakeholders and partners.",
                            "This team is known for the energy and passion that team members bring to work every day.",
                            "People want to work in this team because it is renowned for having a positive work culture which delivers business success.",
                            "This team is known for being inclusive as team members are encouraged to take part, and feel listened to and valued for who they are."
                        );
    
    await Breakdown     (
                            doc,
                            "Values and Beliefs",
                            participants,
                            13,
                            18,
                            "The values and beliefs of the team provides a sense of what is right and wrong. The team explores and agrees on the culture is desires and the values, standards, and behaviours that will underpin their efforts. A shared belief in their purpose, identity, and values are essential to reaching high performance.",
                            "Team members are agreed on how to behave as a team, when together and apart.",
                            "Team members treat each other with dignity and respect.",
                            "This team shows a commitment to team diversity, sustainability, and ethical practice.",
                            "Team members value collective success more than individual achievement.",
                            "Team members believe that it is important for this team to be a team.",
                            "This team's shared values and beliefs shine through in all of its work projects.",
                        );
    
    await Breakdown     (
                            doc,
                            "Awareness",
                            participants,
                            19,
                            24,
                            "The team increases their awareness and consciousness of each other's strengths and personal preferences, alongside how to leverage them beneficially for the team, as a whole. They also develop an awareness of how they interact with their wider stakeholders and system.",
                            "Members of this team appreciate each other's strengths and how these contribute to team performance.",
                            "Team members are self-aware, helping them to interact effectively with others.",
                            "The team proactively interacts with both internal and external stakeholders.",
                            "The team know how they are interconnected with others, both within and outside of their organisation.",
                            "The team are committed to surfacing performance issues and addressing these quickly and fairly.",
                            "Members of this team have personal development plans in place and share progress openly with each other.",
                        );
    
    await Breakdown     (
                            doc,
                            "Relatedness",
                            participants,
                            25,
                            30,
                            "The team develops their sense of unity and builds mutual trust, support, and understanding. They invest their time in open and honest conversations to push themselves to work more closely together, whilst building stronger, more robust, and more reliable relationships within the wider organisation.",
                            "Relationships in this team are built on trust - team members are open and honest with each other.",
                            "Team members take time to get to know each other - not just what they do, but who they are.",
                            "Team members are never shy of having difficult conversations about the quality of work in this team.",
                            "The safe and open environment within this team encourages team members to express their ideas and risks without fear.",
                            "Conflict in this team is used in a productive way to help achieve win-win solutions.",
                            "This team has a high level of both support and challenge, enabling the team to give and receive constructive feedback.",
                        );

    await Breakdown     (
                            doc,
                            "Ways of Working",
                            participants,
                            31,
                            36,
                            "The team invests time in setting up the best systems, practices, and processes to enable their members to make confident and effective decisions. They work on improving the structure of meetings and how they engage with others, in order to deliver concrete and valuable outcomes.",
                            "The team is clear on how it makes collective decisions, with team members committing wholeheartedly once a decision is reached.",
                            "This team is known for the effectiveness of its meetings - they are thought-provoking, engaging, and outcome-focussed.",
                            "Information is exchanged between team members and other teams in a clear and accurate manner.",
                            "This team is continually in touch with its customers and stakeholders, and is committed to helping them succeed.",
                            "This team is known for its agility, helping it to adapt and deliver in the face of challenge and change.",
                            "The team continually review internal and external processes to help improve its effectiveness.",
                        );

    await Breakdown     (
                            doc,
                            "Transformation",
                            participants,
                            37,
                            42,
                            "The team explores different ways to challenge their performance and look for opportunities to test their abilities. They rigorously review their plans, apply innovative ways to think differently, and ensure their team members are always improving, learning, and supporting each other's professional development journeys.",
                            "This team gets together frequently to identiy new ways to grow and sustain its development.",
                            "Everyone in the team is committed to exploring how good the team can be.",
                            "This team embraces change and is known for its new and innovative working practices.",
                            "Team members proactively share their learning, new ideas, and best practice with each other.",
                            "This is a diverse team with an open and inclusive culture that allows every team member to have a voice.",
                            "The team pays attention to the link between the team's well-being and performance."
                        );

    await Summary (doc, participants);

    await Comments (doc, participants.teamStrengths, participants.areasToDevelop, participants.ideasForAction)

}

async function getParticipants (coachId, questionnaireId) {
    const pLOC = collection(doc(collection(doc(collection(db, "Coaches"), coachId), "Questionnaires"), questionnaireId), "Participants");
    const pDOC = await getDocs(pLOC);

    const teamStrengths = [];
    const areasToDevelop = [];
    const ideasForAction = [];
    
    const teamMembers = [];
    const directReports = [];
    const teamCommissioners = [];
    const stakeholders = [];

    for (let i = 0; i < 42; i++) {
        teamMembers.push([]);
        directReports.push([]);
        teamCommissioners.push([]);
        stakeholders.push([]);
    }

    let numTeamMembers = 0;
    let numDirectReports = 0;
    let numTeamCommissioners = 0;
    let numStakeholders = 0;

    pDOC.forEach((contributor) => {
        const participant = contributor.data();

        if (participant.q42 === 0) {
            return;
        };

        const category = participant.category;;
        const teamStrength = participant.qTS;
        const areaToDevelop = participant.qAD;
        const ideaForAction = participant.qIA;

        if (teamStrength.replace(" ", "").length > 0) teamStrengths.push(teamStrength);
        if (areaToDevelop.replace(" ", "").length > 0) areasToDevelop.push(areaToDevelop);
        if (ideaForAction.replace(" ", "").length > 0) ideasForAction.push(ideaForAction);
            
            if (category === "Team Member") {
                numTeamMembers++;
                for (let number = 0; number < 42; number++) {
                    const question = `q${number+1}`;
                    teamMembers[number].push(participant[question]);
                }
            } else if (category === "Direct Report") {
                numDirectReports++;
                for (let number = 0; number < 42; number++) {
                    const question = `q${number+1}`;
                    directReports[number].push(participant[question]);
                }
            } else if (category === "Team Commissioner") {
                numTeamCommissioners++;
                for (let number = 0; number < 42; number++) {
                    const question = `q${number+1}`;
                    teamCommissioners[number].push(participant[question]);
                }
            } else if (category === "Stakeholder") {
                numStakeholders++;
                for (let number = 0; number < 42; number++) {
                    const question = `q${number+1}`;
                    stakeholders[number].push(participant[question]);
                }
            }

    });

    const allParticipants = {
        numTeamMembers: numTeamMembers,
        numDirectReports: numDirectReports,
        numTeamCommissioners: numTeamCommissioners,
        numStakeholders: numStakeholders,
        teamMembers: teamMembers,
        directReports: directReports,
        teamCommissioners: teamCommissioners,
        stakeholders: stakeholders,
        teamStrengths: teamStrengths,
        areasToDevelop: areasToDevelop,
        ideasForAction: ideasForAction
    };

    return allParticipants;
}

async function setupReport (coachId, coach, questionnaire) {
    const participants = await getParticipants(coachId, questionnaire.id);
    const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4"
    });

    doc.setProperties({title: `Team 360 Report for the team named: ${questionnaire.team_name}`});
    doc.setFont("futura-pt-book", "normal");

    await getReportContent(doc, participants, questionnaire);

    // Page numbers
    var totalPages = doc.getNumberOfPages();
    for (var page = 2; page <= totalPages; page++) {
        doc.setPage(page);
        doc.setFillColor(colours.auxiliary);
        doc.setDrawColor(colours.auxiliary);
        doc.setLineWidth(0); 
        doc.rect(sizes.pageWidth-sizes.marginX, 17.5, sizes.marginX*2, 10, "F");
        c(doc, `${page} of ${totalPages}`, {offsetX: sizes.pageWidth-sizes.marginX, offsetY: 11.25, sizeX: sizes.marginX*2, align: "center"});
    }

    window.open(doc.output("bloburl", {filename: `Team 360 Report for the team named ${questionnaire.team_name}`}), "_blank");
} 

export default function generateReport (coachId, coach, questionnaire) {
    setupReport(coachId, coach, questionnaire);
}